@import "styles/variables.scss"; @import "styles/colours.scss"; @import "styles/mixins.scss"; @import "styles/typography.scss";
@import 'variables';
@import 'typography';
@import 'mixins';
@import 'buttons';

.container {
  background-color: $grey-100;
  padding-bottom: 4rem;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 0.5rem;
  max-width: 1320px;

  @include screen-size('medium') {
    flex-direction: row;
    margin: 0 1rem;
  }

  @include screen-size('custom', 1352px) {
    margin: 0 auto;
  }
}

.column {
  display: flex;
  margin: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;

  @include screen-size('medium') {
    max-height: 35rem;
  }
}

.button-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 1.5rem;

  @include screen-size('medium') {
    margin-top: 2.5rem;
  }
}

.button-filled {
  @include button-lg;
  @include button-filled;
  @include text-md;
  @include text-bold;
  @include font-secondary;

  padding: 0.625rem;

  @include screen-size('medium') {
    flex-grow: 0;
    padding: 0.625rem 1.125rem;
  }
}

.button-text {
  @include button-lg;
  @include button-plain;
  @include text-md;
  @include text-bold;
  @include font-secondary;

  padding: 0.625rem;

  @include screen-size('medium') {
    padding: 0.625rem 1.125rem;
  }
}

.heading {
  @include display-lg;
  @include text-bold;
  @include font-primary;

  margin-bottom: 0.5rem;

  @include screen-size('medium') {
    @include display-xl;
  }
}

.text {
  @include text-xl;
  @include text-bold;
  @include font-secondary;

  @include screen-size('medium') {
    width: 350px;
  }
}

.loco-rewards {
  background-color: $primary-yellow;
  border-radius: 1.5rem;
  flex-grow: 1;
  overflow: hidden;
  padding: 3rem 1rem 1rem;
  position: relative;

  @include screen-size('medium') {
    padding: 3rem;
  }

  &::before {
    background-image: url('https://assets.leaseloco.com/homepage/group-confetti.svg');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    height: 170px;
    position: absolute;
    right: -4.25rem;
    top: -1.125rem;
    width: 144px;

    @include screen-size('medium') {
      right: 0;
      top: -1rem;
    }
  }

  .button-filled {
    background-color: $primary-pink;
    color: $white;

    &:hover {
      background-color: $primary-pink;
      color: $white;
    }

    img {
      filter: $white;
    }
  }

  .button-text {
    color: $grey-900;

    img {
      filter: $grey-900-filter;
    }

    &:hover {
      color: $grey-900;
    }
  }
}

.refer-a-friend {
  background-color: $secondary-maroon;
  border-radius: 1.5rem;
  flex-grow: 1;
  overflow: hidden;
  padding: 3rem 1rem 1rem;
  position: relative;

  @include screen-size('medium') {
    padding: 3rem;
  }

  &::before {
    background-image: url('https://assets.leaseloco.com/homepage/cta-confetti-raf.svg');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    height: 510px;
    position: absolute;
    right: -23rem;
    top: -16rem;
    width: 510px;
    z-index: 0;

    @include screen-size('medium') {
      right: -20rem;
      top: -17rem;
    }
  }

  .heading {
    color: $white;
    position: relative;
    z-index: 5;
  }

  .text {
    color: $white;
    position: relative;
    z-index: 5;

    @include screen-size('medium') {
      width: 457px;
    }
  }

  .button-filled {
    background-color: $white;
    color: $secondary-violet;

    &:hover {
      background-color: $white;
      color: $secondary-violet;
    }

    img {
      filter: $secondary-violet-filter;
    }
  }

  .button-text {
    color: $white;

    img {
      filter: $white-filter;
    }

    &:hover {
      color: $white;
    }
  }
}
