// 2022 style guide

@mixin button-sm {
  @include text-sm;
  @include text-bold;
  @include font-secondary;

  padding: 0.5rem 0.875rem;
  gap: 0.25rem;
}

@mixin button-md {
  @include text-sm;
  @include text-bold;
  @include font-secondary;

  padding: 0.625rem 1rem;
  gap: 0.25rem;
}

@mixin button-lg {
  @include text-md;
  @include text-bold;
  @include font-secondary;

  padding: 0.625rem 1.125rem;
  gap: 0.375rem;
}

@mixin button-xl {
  @include text-md;
  @include text-bold;
  @include font-secondary;

  padding: 0.75rem 1rem;
  gap: 0.625rem;
}

@mixin button-filled {
  display: flex;
  align-items: center;
  border-radius: 100px;
  background-color: $primary-pink;
  color: $white;
  cursor: pointer;
  text-decoration: none;

  img {
    filter: invert(100%) sepia(2%) saturate(2%) hue-rotate(221deg) brightness(110%) contrast(101%);
  }

  &:hover {
    background-color: $secondary-700;
    color: $white;
    text-decoration: none;
  }

  &:disabled {
    background-color: $grey-100;
    color: $grey-500;
    cursor: auto;
  }
}

@mixin button-grey {
  display: flex;
  align-items: center;
  border-radius: 100px;
  background-color: $grey-200;
  color: $secondary-700;
  cursor: pointer;
  text-decoration: none;

  img {
    filter: invert(20%) sepia(76%) saturate(5469%) hue-rotate(342deg) brightness(88%) contrast(93%);
  }

  &:hover {
    background-color: $grey-300;
    color: $secondary-700;
    text-decoration: none;
  }

  &:disabled {
    background-color: $grey-100;
    color: $grey-500;
    cursor: auto;
  }
}

@mixin button-plain {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $secondary-500;
  cursor: pointer;
  text-decoration: none;

  img {
    filter: invert(20%) sepia(76%) saturate(5469%) hue-rotate(342deg) brightness(88%) contrast(93%);
  }

  &:hover {
    background-color: transparent;
    color: $secondary-500;
    text-decoration: none;
  }

  &:disabled {
    background-color: $grey-100;
    color: $grey-500;
    cursor: auto;
  }
}

@mixin button-success {
  display: flex;
  align-items: center;
  border-radius: 100px;
  background-color: $button-background-success;
  color: $white;
  cursor: pointer;
  text-decoration: none;

  img {
    filter: invert(100%) sepia(2%) saturate(2%) hue-rotate(221deg) brightness(110%) contrast(101%);
  }

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &:disabled {
    background-color: $grey-100;
    color: $grey-500;
    cursor: auto;
  }
}
